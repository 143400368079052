import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";

import imgMobile from "../../assets/image/jpeg/prphairloss.jpg";
import Recovery from "../../sections/rhino/rhinore.js";
import icon3dModel from "../../assets/image/png/icon-3d-model.png";
import iconLayout from "../../assets/image/png/icon-layot-25.png";
import SectionOne from '../../sections/countersection/countersection';
import Newarrow from '../../assets/image/jpeg/enter.svg'





const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;






const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;

const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
 

}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 2em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: rgb(171, 142, 102);
font-size: 1em;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 16px;
}

`;



const SecondText = styled.h3`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 2.2em;

      margin-bottom: 0.4em;
     
      color: #111;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 16px;
   
      }

`;


const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const PRPhairBeardContPage = ({setIsModalOpen}) => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section>
      <Container>

          <Row>
              <Col>
                <Iwrap>


<Itext as="h2">PRP Treatment for Hair Loss London</Itext>
</Iwrap>
      <SecondText>
      What is Platelet-Rich Plasma (PRP) London for Hair Loss

      </SecondText>
              </Col>
          </Row>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4 mb-lg-0">
            <div
              className="pl-0"
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <ImgStyled src={imgMobile} alt="prp beard restoration" className="img-fluid" />
            </div>
          </Col>
          <Col lg="6" md="9">
            <Box className=" pt-lg-0">
          
              <Text>

         
              
              For men and women suffering from hair thinning, hair loss, or pattern baldness, Platelet-Rich Plasma (PRP) London is a leading, safe, and natural non-surgical treatment. This renowned PRP hair loss treatment supports the growth of existing hair follicles, making your hair appear fuller and healthier, combatting hair loss. <br /> <br /> 

We understand that hair loss can be quite distressing. By utilising the healing characteristics of your own blood, PRP hair loss treatment London is an excellent treatment for hair loss that will provide you with the renewable you require, leaving you with naturally fuller and thicker hair.







              </Text>
            
            
             <br /> <br />
              <div onClick={() => setIsModalOpen(true)}
                 activeClassName="active"
              >
                <Bookbutton>
                  Book Appointment Online
                </Bookbutton>
              </div>
           
            </Box>
          </Col>
        </Row>




        
        <Row className="align-items-center">
         
          <Col lg="12" md="9">
            <Box className=" pt-lg-0">
            <br /><br />
            <SecondText>
            How does PRP for hair loss work?

      </SecondText>
          
              <Text>
              This hair loss treatment works by stimulating your hair follicles, improving scalp blood circulation, and promoting tissue healing. A one-of-a-kind  that stimulates the creation of growth factors, which leads to cell regeneration, with the use of your own plasma. <br /> <br />

Plasma is required because it includes a high concentration of growth hormones, nutrients, and bioactive proteins, which aid in cell renewal and tissue loss.<br /> <br />

Platelets are little blood  that circulate in our bloodstream and bind together when they detect damage, forming blood clots (mesh) to stop bleeding and promote healing.<br /> <br />

PRP hair loss  London reintroduces these two beneficial growth-stimulating components of the blood into specific areas of the scalp via a thin needle. They act in conjunction with the body's natural restorative capacities to stimulate and repair damaged/weak hair follicles, resulting in hair strengthening and significant hair growth benefits, aiding hair loss. <br /> <br />

              </Text>


              <SecondText>
              The London PRP hair loss treatment has 3 steps, which is as follows:

      </SecondText>
          
              <Text>
              1.	A sample size amount of blood will be extracted from your arm and placed in a special tube. After that, the tube is inserted into a centrifuge.  <br /> <br />
2.	The blood sample is then centrifuged. The platelets are separated from the total blood  using a centrifuge, resulting in your own Platelet Rich Plasma. <br /> <br />
3.	The highly concentrated PRP is extracted and reinserted into parts of the scalp where there is hair loss and thinning, using the prepared syringes. <br /> <br />
 

              </Text>


              <SecondText>
              PRP for Hair Loss Results 

      </SecondText>
          
              <Text>
              Platelet Rich Plasma (PRP)  for hair loss is a recent discovery, with numerous scientific and academic research supporting and confirming the use of PRP injections as a successful type of aesthetic treatment for hair loss and hair follicle regeneration. <br /> <br />

The ultimate outcome of any treatment is determined by the individual's biological and genetical traits, lifestyle variables, other causes of hair loss, hair texture and density, and other considerations.<br /> <br />

Before beginning treatment, we will conduct a full assessment to build a personalised treatment plan that is suited to your needs and suitability.<br /> <br />


              </Text>


              <SecondText>
              PRP treatment sessions


      </SecondText>
          
              <Text>
              

4-6 sessions are ideally needed depending on the individual, for a full course treatment<br /> <br />

In order to get the optimum results, one session should be done once a month, followed by monthly maintenance sessions.<br /> <br />

The number of sessions needed and the expected results vary from person to person; this will be discussed during your evaluation and during your consultation, which is included with every treatment.<br /> <br />


              </Text>


              <SecondText>
              Why choose PRP hair loss treatment?


      </SecondText>
          
              <Text>
              

              PRP Hair Treatment is a one-of-a-kind and cutting-edge treatment for thinning hair and hair loss. It employs your body's own  to assist in restoring hair growth without surgery or the use of any external substances.<br /> <br />

PRP treatment begins with the collection of a sample of your own blood, which is then centrifuged to produce platelet-rich plasma, which activates growth factors and other healing-promoting substances in your own body. This concentrated PRP is injected into the scalp to stimulate hair follicles where there is hair loss and thinning.<br /> <br />

Not only can PRP help to prevent hair loss, but it can also help to encourage new hair growth. People with alopecia or other immunological problems, as well as those who have had hair transplants, may have extraordinary improvements.<br /> <br />



              </Text>


              <SecondText>
              What are the benefits of London PRP hair loss treatment?


      </SecondText>
          
              <Text>
              

              Pure ingredients:<br />
The use of your own PRP provides you with the assurance that only a natural liquid is permeating your scalp to encourage the growth of new hair , and reduce hair loss <br /> <br />

Harmless:<br />
It is a minimally invasive, non-surgical procedure. There's also no risk of an allergic reaction or the substance being rejected because we're not injecting anything foreign – we'll only use your own .<br /> <br />

Quick and no recovery needed:<br />
There is no need for recuperation time following a PRP session; you can resume your normal routine right away. Appointments are only 30 minutes long, with PRP treatment requiring less than 10 minutes.<br /> <br />

Minor discomfort:<br />
PRP is injected into the skin using very small needles. On request, numbing cream can also be used.<br /> <br />

Results:<br />
You might observe long-term results. Although the treatment's full effects may take some time to manifest, this approach is effective for many people.<br /> <br />




              </Text>


              <SecondText>
              Is PRP hair loss treatment right for me?


      </SecondText>
          
              <Text>
              

              PRP hair loss  can help those with thinning/loss in hair. If you have alopecia or other immunological problems, the outcomes can be genuinely life-changing.<br /> <br />

We will have a full conversation with you to understand your concerns and determine whether or not what you want can be accomplished. We're here to help you on your individual path.<br /> <br />





              </Text>
            
            
              <SecondText>
              Who is suitable for the PRP hair loss treatment?


      </SecondText>
          
              <Text>
              
              -	PRP is appropriate for men and women who experience hair loss, hair thinning or pattern baldness. <br />
-	Highly beneficial for men and women suffering from all forms of alopecia. <br />
-	Not effective on patients completely bald as the hair follicles have to be active in order for PRP to be its best.<br />
-	Patients aged 18 and over. <br />
-	Patients that do not respond to hair growth pills and different hair loss treatments.<br />
-	Suitable for patients that have had or are having a hair transplant.






              </Text>
            
           
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default PRPhairBeardContPage;
